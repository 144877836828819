/**
 * Main class for interacting with local storage data.
 */
export default class Storage {
    /**
     * Local storage key for the location id.
     */
    static LOCATION_ID = 'axes-location-id';

    /**
     * Local storage key for the lane number
     */
    static LANE_NUMBER = 'axes-lane-number';

    /**
     * Local storage key for the employee keypad codes
     */
    static EMPLOYEE_CODES = 'axes-employee-codes';

    /**
     * Fetch the location id from local storage.
     */
    static getLocationId () {
        return window.localStorage.getItem(this.LOCATION_ID);
    }

    /**
     * Set the location id in local storage
     * 
     * @param locationId The location ID to save into storage.
     */
    static setLocationId (locationId) {
        window.localStorage.setItem(this.LOCATION_ID, locationId);
    }

    /**
     * Fetch the lane number from local storage.
     */
    static getLaneNumber () {
        return window.localStorage.getItem(this.LANE_NUMBER);
    }

    /**
     * Set the lane number in local storage
     * 
     * @param laneNumber The lane number to save into storage.
     */
    static setLaneNumber (laneNumber) {
        window.localStorage.setItem(this.LANE_NUMBER, laneNumber);
    }

    /**
     * Set the employee codes in local storage
     * 
     * @param employeeCodes The lane number to save into storage.
     */
    static setEmployeeCodes (employeeCodes) {
        window.localStorage.setItem(this.EMPLOYEE_CODES, JSON.stringify(employeeCodes));
    }

    /**
     * Get the employee codes from local storage
     */
    static getEmployeeCodes () {
        return JSON.parse(window.localStorage.getItem('axes-employee-codes'));
    }

}