import React, { useState, useEffect } from 'react'
import Request from '../lib/Request'
import Endpoints from '../lib/Endpoints'

export default function LaneInfo({ laneNumber }) {

    const [resource, setResource] = useState(null)

    useEffect(() => {
        if (Number.parseInt(laneNumber) === Number.parseInt(resource?.order)) {
            return
        }

        const locationId = localStorage.getItem('axes-location-id')

        if (!laneNumber || !locationId) {
            return
        }


        Request.get(Endpoints.getResource(locationId, laneNumber)).then(({ data }) => {
            if (data.error) {
                setResource(null)
                return
            }

            setResource(data)
        }).catch((e) => {
            console.warn('Could not load lane: ', e)
        })
    }, [laneNumber, resource])

    return (
        <div id="lane-number">
            <h2>{resource?.name || `Lane ${laneNumber}`}</h2>
        </div>
    )
}
