import React from 'react';
// import Display from '../lib/Display';

export default class ModalThrowCount extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            throwCount: ''
        }
    }

    clearAndClose = () => {
        this.setState({ throwCount: '' });
        this.props.toggleModal(false);
    }

    updateAndClear = () => {
        let throws = this.state.throwCount;
        this.props.editThrows(throws)
        this.setState({ throwCount: '' });
    }

    updateThrowCount = (e) => this.setState({ throwCount: e.target.value });

    render() {
        return (
            <div className={`modal modal-score-21 underlay ${this.props.showModal ? "active" : ""}`}>
                <div className="modal-body">
                    <div className="field-container team-name-1" style={{ padding: "0px" }}>
                        <div className="input-group">
                            <label>Number of Throws</label>
                            <input
                                type="tel"
                                name='team1'
                                onChange={this.updateThrowCount}
                                value={this.state.throwCount} 
                                autoComplete="off" />
                        </div>
                    </div>
                    <div className="actions" style={{marginLeft: ".1rem"}}>
                        <div className="btn btn-outline btn-block btn-close" style={{ display: "inline-block", marginRight: ".1rem" }} onClick={this.clearAndClose}>Cancel</div>
                        <div className="btn btn-gold btn-block btn-close" style={{ display: "inline-block" }} onClick={this.updateAndClear}>Submit</div>
                    </div>
                </div>
            </div>
        )
    }
}