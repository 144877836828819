import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Dispatcher from '../dispatchers/Dispatcher';

export default class FirstTo21 {

    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static show21WinModal = () => {
        Display.toggle21WinModal(true);
        setTimeout( () => {
            Display.toggle21WinModal(false);
        }, 1500);
    }

    static showBustResetModal = () => {
        Display.toggleBustResetModal(true);
        setTimeout( () => {
            Display.toggleBustResetModal(false);
        }, 1500);
    }

    static showBustStayModal = () => {
        Display.toggleBustStayModal(true);
        setTimeout( () => {
            Display.toggleBustStayModal(false);
        }, 1500);
    }

    static evalHitAction = (player, team, scoringStyle, bustOrStay, pts) => {
        if (scoringStyle === 'Individual') {
            if (player.singleScore + pts === 21) {
                // let hitData = { playerId: player.id, teamId: team.id, score: (player.singleScore + pts) }
                // this.toggle(Actions.EDIT_PLAYER_SCORE, hitData);
                this.show21WinModal();
            } else if (bustOrStay === 'Bust') {
                if (player.singleScore + pts <= 21) {
                    // let hitData = { playerId: player.id, teamId: team.id, score: (player.singleScore + pts) }
                    // this.toggle(Actions.EDIT_PLAYER_SCORE, hitData);
                } else {
                    // let hitData = { playerId: player.id, teamId: team, score: 13 }
                    // this.toggle(Actions.EDIT_PLAYER_SCORE, hitData);
                    this.showBustResetModal();
                }
            } else {
                if (player.singleScore + pts <= 21) {
                    // let hitData = { playerId: player.id, teamId: team.id, score: (player.singleScore + pts) }
                    // this.toggle(Actions.EDIT_PLAYER_SCORE, hitData);
                } else {
                    this.showBustStayModal();
                }
            }
        } else {
            if (team.singleScore + pts === 21) {
                // let hitData = { teamId: team.id, score: (team.singleScore + pts) }
                // this.toggle(Actions.EDIT_TEAM_SCORE, hitData);
                this.show21WinModal();
            }
            else if (bustOrStay === 'Bust') {
                if (team.singleScore + pts <= 21) {
                    // let hitData = { teamId: team.id, score: (team.singleScore + pts) }
                    // this.toggle(Actions.EDIT_TEAM_SCORE, hitData);
                } else {
                    // let hitData = { teamId: team.id, score: 13 }
                    // this.toggle(Actions.EDIT_TEAM_SCORE, hitData);
                    this.showBustResetModal();
                }
            } else {
                if (team.singleScore + pts <= 21) {
                    // let hitData = { teamId: team.id, score: (team.singleScore + pts) }
                    // this.toggle(Actions.EDIT_TEAM_SCORE, hitData);
                } else {
                    this.showBustStayModal();
                }
            }
        }
    }

    static editTempScoreForPlayer = (playerId, pts) => {
        let hitData = { playerId: playerId, score: pts }
        this.toggle(Actions.TEMP_EDIT_PLAYER_SCORE, hitData);
    }

    static editTempScoreForTeam = (teamId, pts) => {
        let hitData = { teamId: teamId, score: pts }
        this.toggle(Actions.TEMP_EDIT_TEAM_SCORE, hitData);
    }

    static editTempThrowsForPlayer = (playerId, throws) => {
        let hitData = { playerId: playerId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_PLAYER_THROWS, hitData);
    }

    static editTempThrowsForTeam = (teamId, throws) => {
        let hitData = { teamId: teamId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_TEAM_THROWS, hitData);
    }

    static getPlayerDisplayScore = (playerData) => {
        return playerData.singleScore + " pts"
    }

    static getTeamDisplayScore = (teamData) => {
        return teamData ? " - " + teamData.singleScore : '';
    }

    static playerGameStatus = (playerData, scoringStyle, teamData) => {
        if (scoringStyle === 'Individual') {
            return playerData.singleScore === 21;
        } else {
            return teamData[playerData.teamId-1].singleScore === 21;
        }
    }
}