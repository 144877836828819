import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import FirstTo21 from '../lib/FirstTo21';
import BucketList from '../lib/BucketList';
import Modal21Win from './Modal21Win';
import ModalBustStay from './ModalBustStay';
import MagicNumber from '../lib/MagicNumber';
import ModalBustReset from './ModalBustReset';
// import ModalTargetMenu from './ModalTargetMenu';
import ModalRingClosed from './ModalRingClosed';
// import ModalScoreMessage from './ModalScoreMessage';
import ModalWinnerMessage from './ModalWinnerMessage';
import ModalPlayerCompleted from './ModalPlayerCompleted';

export default class Target extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logScore: null,
            points: null,
            popClass: null
        };
    }

    cancelLogScore = () => {
        this.setState({ logScore: false });
    }

    addPopClass = (pts) => {
        switch (pts) {
            case 6:
                this.setState({popClass: 'action-bull'});
                break;
            case 4:
                this.setState({popClass: 'action-ring1'});
                break;
            case 3:
                this.setState({popClass: 'action-ring2'});
                break;
            case 2:
                this.setState({popClass: 'action-ring3'});
                break;
            case 1:
                this.setState({popClass: 'action-ring4'});
                break;
            case 0:
                this.setState({popClass: 'action-miss'});
                break;
            case 10: case 'blue':
                this.setState({popClass: 'action-ks'});
                break;
            default:
                return null;
        }
    }

    calculateHit = (pts) => {
        this.addPopClass(pts);
        this.setState({logScore: true, points: pts});
        Display.toggleShowHitModal(true);

        setTimeout( () => {
            // HitModal can change the logScore state variable
            // If logScore is still true as the modal closes, we want to log the hit
            if (this.state.logScore) {
                if (this.props.game.game === 'League Style') {
                    Game.createHitForPlayer(this.props.game.currentThrower, pts, this.props.game.gameState, this.props.game.scoringStyle);
                } else if (this.props.game.game === 'First to 21') {
                    FirstTo21.evalHitAction(this.props.game.currentThrower, this.props.game.currentTeam, this.props.game.scoringStyle, this.props.game.bustOrStay, pts);
                } else if (this.props.game.game === 'Bucket List') {
                    BucketList.evalHitAction(this.props.game.currentThrower, this.props.game.currentTeam, this.props.game.scoringStyle, this.props.game.bucketListBlueBalls, pts);
                } else if (this.props.game.game === 'Magic Number') {
                    MagicNumber.evalHitAction(this.props.game.currentThrower, this.props.game.currentTeam, this.props.game.scoringStyle, this.props.game.bucketListBlueBalls, pts);
                }
            }
            Display.toggleShowHitModal(false);
            this.setState({popClass: null});
            // TODO: Change back to 3000
        }, 1500);
        
    }

    calculateBluePoints = () => {
        if (this.props.game.game === 'League Style') {
            this.calculateHit('blue');
        } else if (this.props.game.game === 'Bucket List') {
            this.props.game.bucketListBlueBalls ? this.calculateHit('blue') : this.calculateHit(1);
        } else if (this.props.game.game === 'Magic Number') {
            this.props.game.magicNumberBlueBalls ? this.calculateHit('blue') : this.calculateHit(1);
        } else {
            this.calculateHit(10);
        }
    }

    showBluePoints = () => {
        if (this.props.game.game === 'First to 21') {
            return 10;
        } else if (this.props.game.game === 'Bucket List') {
            return this.props.game.bucketListBlueBalls ? 'B' : '1';
        } else if (this.props.game.game === 'Magic Number') {
            return this.props.game.magicNumberBlueBalls ? 'B' : '1';
        } else {
            if (this.props.game.currentThrower && (this.props.game.currentThrower.throwCount === 4 || this.props.game.currentThrower.throwCount === 9)) {
                return 8;
            } else {
                return 1;
            }
        }
    }

    renderBlueCircle = () => {
        return this.props.game.game !== 'Bucket List' || (this.props.game.game === 'Bucket List' && this.props.game.bucketListBlueBalls)
    }

    render() {
        return (
            <>
                <div id="target-feedback">
                    <div className={`action-bull              ${this.state.popClass === 'action-bull'  ? "pop" : ""}`} onClick={() => this.calculateHit(6)}></div>
                    <div className={`action-ring action-ring1 ${this.state.popClass === 'action-ring1' ? "pop" : ""}`} onClick={() => this.calculateHit(4)}></div>
                    <div className={`action-ring action-ring2 ${this.state.popClass === 'action-ring2' ? "pop" : ""}`} onClick={() => this.calculateHit(3)}></div>
                    <div className={`action-ring action-ring3 ${this.state.popClass === 'action-ring3' ? "pop" : ""}`} onClick={() => this.calculateHit(2)}></div>
                    <div className={`action-ring action-ring4 ${this.state.popClass === 'action-ring4' ? "pop" : ""}`} onClick={() => this.calculateHit(1)}></div>
                    <div className={`action-ring action-miss  ${this.state.popClass === 'action-mis'   ? "pop" : ""}`} onClick={() => this.calculateHit(0)}></div>
                    <div className={`action-ks                ${this.state.popClass === 'action-ks'    ? "pop" : ""}`} onClick={() => this.calculateBluePoints()}></div>
                </div>
                <div id="target">

                    <div className="ring-miss"     onClick={() => this.calculateHit(0)}></div>
                    <div className="ring-one"      onClick={() => this.calculateHit(1)}></div>
                    <div className="ring-two"      onClick={() => this.calculateHit(2)}></div>
                    <div className="ring-three"    onClick={() => this.calculateHit(3)}></div>
                    <div className="ring-four"     onClick={() => this.calculateHit(4)}></div>
                    <div className="ring-bullseye" onClick={() => this.calculateHit(6)}></div>
                    <div className="ring-blue"     onClick={() => this.calculateBluePoints()}></div>

                    <div className="point-labels">
                        <div className="point-label bull"  onClick={() => this.calculateHit(6)}>6</div>
                        <div className="point-label ring1" onClick={() => this.calculateHit(4)}>4</div>
                        <div className="point-label ring2" onClick={() => this.calculateHit(3)}>3</div>
                        <div className="point-label ring3" onClick={() => this.calculateHit(2)}>2</div>
                        <div className="point-label ring4" onClick={() => this.calculateHit(1)}>1</div>
                        <div className="point-label miss"  onClick={() => this.calculateHit(0)}>Miss/Drop</div>
                        <div className="point-label ks"    onClick={() => this.calculateBluePoints()}>{this.showBluePoints()}</div>
                    </div>
                </div>

                {/* <ModalScoreMessage 
                    // {...this.props} 
                    hitMessages={this.props.app.hitMessages}
                    missMessages={this.props.app.missMessages}
                    game={this.props.game ? true : false}
                    currentThrower={this.props.game && this.props.game.currentThrower ? this.props.game.currentThrower : null}
                    throwCount={this.props.game && this.props.game.throwCount ? this.props.game.throwCount : null}
                    showHitModal={this.props.display.showHitModal}
                    cancelLogScore={this.cancelLogScore}
                    logScore={this.state.logScore} 
                    points={this.state.points} /> */}
                
                <ModalPlayerCompleted showModal={this.props.display.showPlayerStatusModal} message={this.props.display.playerStatusMessage} />

                <ModalWinnerMessage showModal={this.props.display.showWinnerModal} message={this.props.display.winnerMessage} />

                { (this.props.game.game === 'Bucket List' || this.props.game.game === 'Magic Number') && 
                     <ModalRingClosed showModal={this.props.display.showRingClosedModal} message={this.props.display.ringClosedMessage} />
                }

                { this.props.game.game === 'First to 21' && 
                     <Modal21Win showModal={this.props.display.show21WinModal} />
                }

                { this.props.game.game === 'First to 21' && this.props.game.bustOrStay === 'Bust' &&
                    <ModalBustReset showModal={this.props.display.showBustResetModal} />
                }

                { this.props.game.game === 'First to 21' && this.props.game.bustOrStay === 'Stay' && this.props.game.scoringStyle === 'Individual' &&
                    <ModalBustStay 
                        showModal={this.props.display.showBustStayModal} 
                        currentScore={this.props.game.currentThrower ? this.props.game.currentThrower.singleScore : 0} />
                }

                { this.props.game.game === 'First to 21' && this.props.game.bustOrStay === 'Stay' && this.props.game.scoringStyle !== 'Individual' &&
                    <ModalBustStay 
                        showModal={this.props.display.showBustStayModal} 
                        currentScore={this.props.game.currentTeam ? this.props.game.currentTeam.singleScore : 0} />
                }
            </>
        )
    }
}