import React from 'react';
// import Game from '../lib/Game';
import Display from '../lib/Display';

export default class ModalWinnerMessage extends React.Component {

    endGame = () => {
        Display.hideWinnerModal(true);
        Display.toggleEndGameModal(true);
    }
    
    render() {
        return (
            <div className={`modal modal-score underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body centered-modal">
                    <h2 style={{paddingBottom: "20px"}}>{this.props.message}</h2>
                    {/* <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px', marginTop: '20px'}} onClick={this.endGame}>End Game Actions</div>
                    <div className="modal-actions" style={{paddingBottom: '20px'}}>
                        <div className="btn btn-outline btn-close" onClick={() => Display.hideWinnerModal(true)}>Close</div>
                    </div> */}
                </div>
            </div>
        )
    }
}