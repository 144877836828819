export default class LeagueStyle {

    static toggleReadyToPlay = (value, test) => {
        return true;
    }

    // Cicle 8 is worth 8 points on throws 5 & 10 (4/9 with zero index), 0 otherwise
    static calculateThrowScore = (throwNo, circleHit) => {
        let score;
        if (circleHit === 'blue') {
            (throwNo === 4 || throwNo === 9) ? score = 8 : score = 1;
        } else {
            score = circleHit;
        }
        return score;
    }

    // Get individual throw score for a player
    static getThrowScoreForPlayer = (throwNo, playerData) => {
        let score;
        playerData.throws.forEach((thisThrow, i, throws) =>
            thisThrow.throwNumber === throwNo ? score = this.calculateThrowScore(throwNo, thisThrow.circleHit) : ''
        )
        return score;
    }

    // Get total score of all throws for a plyer
    static getTotalScoreForPlayer = (playerData) => {
        let score = 0;
        playerData.throws.forEach((thisThrow, i, throws) =>
            i < 10 ? score += this.calculateThrowScore(thisThrow.throwNumber, thisThrow.circleHit) : score += 0
        )
        return score;
    }

    static getThrowScoreForTeam = (throwNumber, teamData) => {
        let teamThrowScore = 0;
        if (!teamData) {
            return 0;
        }
        teamData.players.forEach((player, i, players) => 
            player.throws.forEach((thisThrow, i, throws) =>
                thisThrow.throwNumber === throwNumber ? teamThrowScore += this.calculateThrowScore(thisThrow.throwNumber, thisThrow.circleHit) : 0
            )   
        )
        return teamThrowScore;
    }

    static getTotalScoreForTeam = (teamData) => {
        let teamScore = 0;
        if (!teamData) {
            return 0;
        }
        teamData.players.forEach((player, i, players) => 
            teamScore += this.getTotalScoreForPlayer(player)
        )
        return teamScore;
    }

    static getPlayerDisplayScore = (playerData) => {
        let totalPoints = this.getTotalScoreForPlayer(playerData);
        return totalPoints + " pts ("+ playerData.throwCount + " throws)";
    }

    static getTeamDisplayScore = (teamData) => {
        return " - " + this.getTotalScoreForTeam(teamData);
    }

    static playerGameStatus = (playerData) => {
        return playerData.throwCount >= 10 ? true : false;
    }
}