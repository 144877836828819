import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import Modal18Keypad from './Modal18Keypad';
import ModalThrowCount from './ModalThrowCount';
import BucketList from '../lib/BucketList';
import BucketListPlayerScores from './BucketListPlayerScores';

export default class ScoreboardBucketList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playerId: null,
            teamId: null,
            score: null,
            throwNumber: null,
            throwCount: null,
            editingScore: null,
            showThrowModal: null
        }
    }

    toggleThrowModal = (val) => {
        this.setState({ showThrowModal: val });
    }

    renderTeams = (gameState) => {
        if (gameState.length > 0) {
            return gameState.map((team, index) => {
                return (
                    <tbody key={index}>
                        {this.props.game.scoringStyle !== 'Individual' &&
                            <tr className="player">
                                <th className={`${this.props.game.currentTeam !== null && this.props.game.currentTeam.id === team.id ? 'current-thrower' : ''}`}>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                                <td className='td-bl' onClick={() => this.setTeamToEditThrows(team.id)}>{team.throwCount}</td>
                                <td className='td-bl' onClick={() => this.editTeamScore(0, team)}>{BucketList.getBucketScore(0, team)}</td>
                                <td className='td-bl' onClick={() => this.editTeamScore(1, team)}>{BucketList.getBucketScore(1, team)}</td>
                                <td className='td-bl' onClick={() => this.editTeamScore(2, team)}>{BucketList.getBucketScore(2, team)}</td>
                                <td className='td-bl' onClick={() => this.editTeamScore(3, team)}>{BucketList.getBucketScore(3, team)}</td>
                                <td className='td-bl' onClick={() => this.editTeamScore(4, team)}>{BucketList.getBucketScore(4, team)}</td>
                                { this.props.game.bucketListBlueBalls && <td className='td-bl' onClick={() => this.editTeamScore(5, team)}>{BucketList.getBucketScore(5, team)}</td> }
                                {/* <td onClick={() => this.editTeamScore(5, team)}>{BucketList.getBucketScore(5, team)}</td> */}
                            </tr>
                        }
                        {this.props.game.scoringStyle === 'Individual' && team && this.renderPlayerScores(team.players)}
                    </tbody>
                )
            });
        }
    }

    renderPlayerScores = (gameState) => {
        return gameState.map((player, index) => {
            return (
                <BucketListPlayerScores
                    setPlayerToEdit={this.setPlayerToEdit}
                    setPlayerToEditThrows={this.setPlayerToEditThrows}
                    editPlayerScore={this.editPlayerScore}
                    currentThrowerId={this.props.game.currentThrower !== null ? this.props.game.currentThrower.id : null}
                    name={player.name}
                    blueBalls={this.props.game.bucketListBlueBalls}
                    playerData = {player}
                    id={player.id}
                    key={index} />
            );
        });
    }

    setPlayerToEdit = (playerId) => {
        this.setState({playerId: playerId});
    }

    editPlayerScore = (bucketIndex, playerData) => {
        BucketList.editTempScoreForPlayer(bucketIndex, playerData);
    }

    setPlayerToEditThrows = (playerId) => {
        this.setState({playerId: playerId});
        this.toggleThrowModal(true);
    }

    editTeamScore = (bucketIndex, teamData) => {
        BucketList.editTempScoreForTeam(bucketIndex, teamData);
    }

    setTeamToEditThrows = (teamId) => {
        this.setState({teamId: teamId});
        this.toggleThrowModal(true);
    }

    updateScoresAndClose = () => {
        Game.setGameStateToModified();
        if (this.props.game.scoringStyle === 'Individual') {
            BucketList.updatePlayerBucketScoreAfterEdit();
        } else {
            BucketList.updateTeamBucketScoreAfterEdit();
        }
        Display.toggleLeagueScoreboard(false);
    }

    editThrows = (throws) => {
        // If the score is >= 0, we know it's a real score.
        if (throws >= 0) {
            if (this.props.game.scoringStyle === 'Individual') {
                BucketList.editTempThrowsForPlayer(this.state.playerId, throws);
            } else {
                BucketList.editTempThrowsForTeam(this.state.teamId, throws);
            }
        }
        this.toggleThrowModal(false);
    }

    render() {
        let stateType;
        if (!this.props.game.modifyGameState) {
            stateType = this.props.game.gameState;
        } else {
            stateType = this.props.game.modifyGameState
        }
        return (
            <>
                <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
                    <div className="score-grid">
                        <table>
                            <thead>
                                <tr>
                                    <th></th><th>Throws</th><th>1</th><th>2</th><th>3</th><th>4</th><th>Bullseye</th>{ this.props.game.bucketListBlueBalls && <th>Blue Ball</th> }
                                </tr>
                            </thead>
                            {this.renderTeams(stateType)}
                        </table>
                    </div>
                    
                    { (this.props.game.scoringStyle !== 'Individual' && this.props.game.currentThrower) &&
                        <h1 style={{fontSize: ".4rem", marginBottom: ".3rem"}}>Current Thrower: {this.props.game.currentThrower.name}</h1>
                    }

                    <div id="lane-number" style={{bottom: ".6rem"}}>
                        <h2>{this.props.game.game}</h2>
                    </div>
                </div>

                <Modal18Keypad 
                    showModalKeypad18={this.props.display.showModalKeypad18}
                    editPlayerScore={this.editPlayerScore} />

                <ModalThrowCount
                    showModal={this.state.showThrowModal}
                    toggleModal={this.toggleThrowModal}
                    editThrows={this.editThrows} />
            </>
        )
    }
}