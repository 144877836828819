/**
 * Game scoring style helpers
 */
export default class ScoringStyle {

    /**
     * Team total value
     */
    static TEAM_TOTAL = 'Team Total';

    /**
     * Array of styles that require manual configuration of teams (names, etc)
     */
    static stylesThatRequireTeams = [this.TEAM_TOTAL];

    /**
     * Does `style` require configuring teams?
     */
    static styleRequiresTeams = (style) => {
        return this.stylesThatRequireTeams.includes(style);
    }
}