import React from 'react';
import Lane from '../lib/Lane';
import Display from '../lib/Display';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';
import Storage from '../lib/Storage';

export default class ModalAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            locationId: "",
            laneNumber: "",
        }
    }

    componentDidMount = () => {
        let location = Storage.getLocationId();
        let lane     = Storage.getLaneNumber();
        if (location) {
            this.setState({locationId: location});
        }
        if (lane) {
            this.setState({laneNumber: lane});
        }
    }

    fetchEmployeeCodes = () => {
        Request.get(Endpoints.getEmployeeCodes(this.state.locationId)).then((response) => {
            if (response.data.data) {
                Storage.setEmployeeCodes(response.data.data);
                Lane.setEmployeeCodes(response.data.data);
            }
        });
    }

    updateLocationId = (e) => {
        this.setState({ locationId: e.target.value });
    }

    updateLaneNumber = (e) => {
        this.setState({ laneNumber: e.target.value });
    }

    reservationComplete = () => {
        // TODO: Make api request to mark reservation as complete
        window.location.reload(false)
    }

    closeAdmin = () => {
        this.fetchEmployeeCodes();
        Storage.setLocationId(this.state.locationId);
        Storage.setLaneNumber(this.state.laneNumber);
        Lane.setLocationId(this.state.locationId);
        Lane.setLaneNumber(this.state.laneNumber);
        Lane.toggleAccessingAdmin(false);
        Display.toggleAdminModal(false);
    }

    render() {
        return (
            <div className={`modal modal-end underlay ${this.props.showAdminModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h2>Admin</h2>
                    <br></br>
                    { !this.props.groupInLane &&
                        <>
                        <div className="input-group">
                            <label>Location ID</label>
                            <input
                                type="text"
                                name='location-id' 
                                onChange={this.updateLocationId}
                                value={this.state.locationId}
                                autoComplete="new-password" />
                        </div>
                        <div className="input-group">
                            <label>Lane Number</label>
                            <input
                                type="text"
                                name='lane-number' 
                                onChange={this.updateLaneNumber}
                                value={this.state.laneNumber}
                                autoComplete="new-password" />
                        </div>
                        </>
                    }
                    { this.props.reservationNumber && this.props.groupInLane &&
                        <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px'}} onClick={this.reservationComplete}>Reservation Complete</div>
                    }
                    <br></br>
                    <div className="btn btn-blank btn-block btn-close" onClick={this.closeAdmin}>Close</div>
                </div>
            </div>
        )
    }
}