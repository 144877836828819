import Actions from '../lib/Actions';
import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
// import arrayMove from 'array-move';
// import ScoringStyle from '../lib/ScoringStyle';

/**
 * Main store for game states
 */
class GameStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            game: 'null',
            scoringStyle: null,
            readyToPlay: null,

            teams: [],
            players: [],
            score: null,
            round: null,
            currentThrower: null,
            currentTeam: null,
            modifyGameState: null,
            gameState: [],
            bustOrStay: 'Bust',
            bucketListBlueBalls: false,
            magicNumberBlueBalls: false,
            gameEnded: null,
            gameId: null,
            individualPlayers: null,
            individualAssignedPlayers: null,
            teamTotalPlayers: null,
            teamTotalAssignedPlayers: null,
            lanePlayers: null,
            laneAssignedPlayers: null,
            // firstToScore: 21,
            /**
             * The id of the active team that players are being chosen for
             */
            selectPlayersActiveTeam: null
        };
    }

    // Find player based on playerId and add a hit record for them
    addHitForPlayer = (playerId, points, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            throws: player.throws.concat({ throwNumber: player.throws.length, points: points, circleHit: points }),
                            throwCount: player.throws.length + 1
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }

    editThrowForPlayer = (playerId, points, throwNo, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            throws: player.throws.map((throwData) => {
                                if (throwData.throwNumber === throwNo) {
                                    return {
                                        throwNumber: throwData.throwNumber,
                                        points: points,
                                        circleHit: points
                                    }
                                } else {
                                    return throwData;
                                }
                            })
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }

    editScoreForPlayer = (playerId, score, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            singleScore: score,
                            throwCount: player.throwCount += 1
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }

    editScoreForTeam = (teamId, score, teamData) => {
        return teamData.map((team) => {
            if (team.id === teamId) {
                return {
                    ...team,
                    singleScore: score,
                    throwCount: team.throwCount += 1
                }
            } else {
                return team
            }
        })
    }

    editThrowCountForPlayer = (playerId, throwCount, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            throwCount: Number(throwCount)
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }

    editThrowCountForTeam = (teamId, throwCount, teamData) => {
        return teamData.map((team) => {
            if (team.id === teamId) {
                return {
                    ...team,
                    throwCount: Number(throwCount)
                }
            } else {
                return team
            }
        })
    }

    updatePlayerThrowCountAfterEdit = (currentThrower, teamData) => {
        let throws = 0;
        teamData.forEach((team) => {
            team.players.forEach((player) => {
                if (player.id === currentThrower.id) {
                    throws = player.throws.length;
                }
            })
        });

        return {
            ...currentThrower,
            throwCount: throws
        }
    }

    updatePlayerScoreAfterEdit = (currentThrower, teamData) => {
        let score = 0;
        teamData.forEach((team) => {
            team.players.forEach((player) => {
                if (player.id === currentThrower.id) {
                    score = player.singleScore;
                }
            })
        });

        return {
            ...currentThrower,
            singleScore: score
        }
    }

    updateTeamScoreAfterEdit = (currentTeam, teamData) => {
        let score = 0;
        teamData.forEach((team) => {
            if (team.id === currentTeam.id) {
                score = team.singleScore;
            }
        });

        return {
            ...currentTeam,
            singleScore: score
        }
    }

    updateCurrentThrowerScore = (currentThrower, score) => {
        return {
            ...currentThrower,
            singleScore: score
        }
    }
    
    updateCurrentThrowerCount = (currentThrower) => {
        return {
            ...currentThrower,
            throwCount: currentThrower.throwCount += 1
        }
    }

    updateCurrentTeamScore = (currentTeam, score) => {
        return {
            ...currentTeam,
            singleScore: score
        }
    }

    // BUCKET LIST GAME ACTIONS //////////////////////////////////////////////////////////////////////////////////////
    updateCurrentThrowerBucketScore = (currentThrower, score) => {
        return {
            ...currentThrower,
            bucketList: score
        }
    }
    editBucketScoreForPlayer = (playerId, score, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            bucketList: score,
                            throwCount: player.throwCount + 1
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }
    updatePlayerBucketScoreAfterEdit = (currentThrower, teamData) => {
        let bucketScore = [0,0,0,0,0,0];
        teamData.forEach((team) => {
            team.players.forEach((player) => {
                if (player.id === currentThrower.id) {
                    bucketScore = player.bucketList;
                }
            })
        });

        return {
            ...currentThrower,
            bucketList: bucketScore
        }
    }

    updateCurrentTeamBucketScore = (currentTeam, score) => {
        return {
            ...currentTeam,
            bucketList: score
        }
    }
    editBucketScoreForTeam = (teamId, score, teamData) => {
        return teamData.map((team) => {
            if (team.id === teamId) {
                return {
                    ...team,
                    bucketList: score,
                    throwCount: team.throwCount + 1
                }
            } else {
                return team
            }
        })
    }
    updateTeamBucketScoreAfterEdit = (currentTeam, teamData) => {
        let bucketScore = [0,0,0,0,0,0];
        teamData.forEach((team) => {
            if (team.id === currentTeam.id) {
                bucketScore = team.bucketList;
            }
        });

        return {
            ...currentTeam,
            bucketList: bucketScore
        }
    }
    // BUCKET LIST END ????????????????????????????????????????????????????????????????????????????????????????????????


    // MAGIC NUMBER &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    editMagicScoreForPlayer = (playerId, round, throwCount, magicNumberScore, points, teamData) => {
        let editScore = [];
        let throws = throwCount;

        // If points are less than zero, we are clearing out the data for the current round
        if (points < 0) {
            let throwsForRound = 0;
            for (let i = 0; i < 3; i++) {
                typeof(magicNumberScore[round][i]) === 'object' ? console.log('NaN') : throwsForRound += 1;
                editScore[i] = null;
            }
            throws -= throwsForRound;
        // Create appropriate hit records (value: 1) for how many hits they achieved
        } else {
            for (let i = 0; i < 3; i++) {
                if (points > 0) {
                    editScore[i] = 1;
                    points -= 1
                } else {
                    editScore[i] = 0;
                }
            }
        }

        magicNumberScore[round] = editScore;

        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        return {
                            ...player,
                            magicNumber: magicNumberScore,
                            throwCount: throws
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }

    updatePlayerMagicScoreAfterEdit = (currentThrower, teamData) => {
        let magicScore = [];
        let throws = 0;
        teamData.forEach((team) => {
            team.players.forEach((player) => {
                if (player.id === currentThrower.id) {
                    magicScore = player.magicNumber;
                    throws     = player.throwCount;
                }
            })
        });

        return {
            ...currentThrower,
            magicNumber: magicScore,
            throwCount: throws
        }
    }

    logMagicScoreForPlayer = (playerId, round, index, score, teamData) => {
        return teamData.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerId) {
                        let playerScore = player.magicNumber;
                        playerScore[round][index] = score;
                        return {
                            ...player,
                            magicNumber: playerScore,
                            throwCount: player.throwCount + 1
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
    }
    // MAGIC NUMBER END &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&


    // TODO: Must update this for each new gametype scoring system
    clearScoreData = (teamData) => {
        return teamData.map((team) => {
            return {
                ...team,
                singleScore: 0,
                throwCount: 0,
                magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
                bucketList: [0,0,0,0,0,0],
                magicNumberRound: 0,
                players: team.players.map((player) => {
                    return {
                        ...player,
                        throws: [],
                        singleScore: 0,
                        total: 0,
                        throwCount: 0,
                        bucketList: [0,0,0,0,0,0],
                        magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
                        magicNumberRound: 0
                    }
                })
            }
        })
    }

    // TODO: Must update this for each new gametype scoring system
    clearCurrentThrowerData = (currentThrower) => {
        return {
            ...currentThrower,
            singleScore: 0,
            throwCount: 0,
            total: 0,
            throws: [],
            bucketList: [0,0,0,0,0,0],
            magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
            magicNumberRound: 0

        }
    }

    // TODO: Must update this for each new gametype scoring system
    clearCurrentTeamData = (currentTeam) => {
        return {
            ...currentTeam,
            singleScore: 0,
            bucketList: [0,0,0,0,0,0],
            magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
            magicNumberRound: 0,
            throwCount: 0
        }
    }


    /**
     * Generate a team object
     * TODO: Must update this for each new gametype scoring system
     */
    generateTeam = (name, id) => {
        return {
            name:    name || 'Lane',
            id:      id   || 1,
            score: 0,
            singleScore: 0,
            players: [],
            bucketList: [0,0,0,0,0,0],
            magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
            magicNumberRound: 0,
            throwCount: 0
        };
    }

    /**
     * Helper for mapping over the gameState (teams) and calling callback on the active team,
     * leaving the other team as-is. This is just so we don't have to sprinkle the same if
     * statements everywhere.
     */
    updateActiveTeam = (state, callback) => {
        return state.gameState.map((team) => {
            if (team.id === state.selectPlayersActiveTeam) {
                return callback(team);
            } else {
                return team;
            }
        })
    }

    updateTeamSetup = (scoringStyle) => {
        if (scoringStyle === 'Individual') {
            return {
                
            }
        } else if (scoringStyle === 'Team Total') {

        } else {

        }
    }

    setCurrentThrower = (playerId, gameState) => {
        let playerData = {}

        gameState.forEach((team) => {
            team.players.forEach((player) => {
                if (player.id === playerId) {
                    playerData = player;
                }
            })
        });

        return playerData;
    }

    // setCurrentTeam = (teamId, gameState) => {

    // }

    reduce(state, action) {
        switch(action.type) {
            case Actions.UPDATE_GAME_STATE:
                return { ...state, game: action.data.game, readyToPlay: action.data.readyToPlay, gameState: action.data.gameState,
                         scoringStyle: action.data.scoringStyle, currentThrower: action.data.currentThrower, currentTeam: action.data.currentTeam,
                         bucketListBlueBalls: action.data.bucketListBlueBalls, bustOrStay: action.data.bustOrStay, 
                         magicNumberBlueBalls: action.data.magicNumberBlueBalls }
            // case Actions.SET_GAME:
            //     return { ...state, game: action.data };
            // case Actions.SET_SCORING_STYLE:
            //     const scoringStyleRequiresTeam = ScoringStyle.styleRequiresTeams(action.data);
            //     return {
            //         ...state,
            //         scoringStyle: action.data,
            //         /**
            //          * When we have a scoring style that does NOT require teams being configured, we need to configure
            //          * a "system" team so to speak so that players can be assigned to it
            //          */
            //         gameState: scoringStyleRequiresTeam ? state.gameState : [this.generateTeam()],
            //         selectPlayersActiveTeam: scoringStyleRequiresTeam ? state.selectPlayersActiveTeam : this.generateTeam().id
            //     };
            // case Actions.SET_TEAM_NAMES:
            //     const setTeamObjects = action.data.map((teamName, index) => this.generateTeam(teamName, index + 1));
            //     return {
            //          ...state,
            //          teams: action.data,
            //          gameState: setTeamObjects,
            //          selectPlayersActiveTeam: setTeamObjects[0].id
            //     };
            // case Actions.RESET_GAME_STATE:
            //     return {...state, gameState: [], selectPlayersActiveTeam: null, players: []};
            // case Actions.READY_TO_PLAY:
            //     return { ...state, readyToPlay: action.data };

            // case Actions.SET_CURRENT_THROWER:
            //     // return { ...state, currentThrower: { ...action.data, throwCount: action.data.throws.length } }
            //     return { ...state, currentThrower: this.setCurrentThrower(action.data.id, state.gameState) }
            // case Actions.SET_CURRENT_TEAM:
            //     return { ...state, currentTeam: action.data };

            // case Actions.CREATE_HIT_FOR_PLAYER:
            //     return { ...state, currentThrower: this.updateCurrentThrowerCount(state.currentThrower), gameState: this.addHitForPlayer(action.data.id, action.data.points, state.gameState) };

            // case Actions.CREATE_TEMP_HIT_FOR_PLAYER:
            //     return { ...state, modifyGameState: this.addHitForPlayer(action.data.id, action.data.points, state.modifyGameState) };

            // case Actions.EDIT_PLAYER_THROW:
            //     return { ...state, gameState: this.editThrowForPlayer(action.data.playerId, action.data.score, action.data.throwNo, state.gameState) };

            // case Actions.EDIT_TEMP_PLAYER_THROW:
            //     return { ...state, modifyGameState: this.editThrowForPlayer(action.data.playerId, action.data.score, action.data.throwNo, state.modifyGameState) };

            // // First to 21
            // case Actions.EDIT_PLAYER_SCORE:
            //     return { ...state, currentThrower: this.updateCurrentThrowerScore(state.currentThrower, action.data.score), gameState: this.editScoreForPlayer(action.data.playerId, action.data.score, state.gameState) };

            // case Actions.TEMP_EDIT_PLAYER_SCORE:
            //     return { ...state, modifyGameState: this.editScoreForPlayer(action.data.playerId, action.data.score, state.modifyGameState) };
               
            // case Actions.EDIT_TEAM_SCORE:
            //     return { ...state, currentTeam: this.updateCurrentTeamScore(state.currentTeam, action.data.score), gameState: this.editScoreForTeam(action.data.teamId, action.data.score, state.gameState) };

            // case Actions.TEMP_EDIT_TEAM_SCORE:
            //     return { ...state, modifyGameState: this.editScoreForTeam(action.data.teamId, action.data.score, state.modifyGameState) };
            // // End First to 21
            
            // // Temporarily editing throw counts
            // case Actions.TEMP_EDIT_PLAYER_THROWS:
            //     return { ...state, modifyGameState: this.editThrowCountForPlayer(action.data.playerId, action.data.throws, state.modifyGameState) };
             
            // case Actions.TEMP_EDIT_TEAM_THROWS:
            //     return { ...state, modifyGameState: this.editThrowCountForTeam(action.data.teamId, action.data.throws, state.modifyGameState) };

            // case Actions.UPDATE_PLAYER_THROW_COUNT_AFTER_EDIT:
            //     return { ...state, currentThrower: this.updatePlayerThrowCountAfterEdit(state.currentThrower, state.modifyGameState) };

            // case Actions.UPDATE_PLAYER_SCORE_AFTER_EDIT:
            //     return { ...state, currentThrower: this.updatePlayerScoreAfterEdit(state.currentThrower, state.modifyGameState) };
            
            // case Actions.UPDATE_TEAM_SCORE_AFTER_EDIT:
            //     return { ...state, currentTeam: this.updateTeamScoreAfterEdit(state.currentTeam, state.modifyGameState) };

            // case Actions.SET_MODIFY_GAME_STATE:
            //     return { ...state, modifyGameState: [ ...state.gameState ] };
                
            // case Actions.SET_GAME_STATE_TO_MODIFIED:
            //     return { ...state, gameState: [ ...state.modifyGameState ] };

            // case Actions.ASSIGN_PLAYER_TO_TEAM:
            //     // TODO: Update for new scoring systems
            //     return {
            //         ...state,
            //         gameState: this.updateActiveTeam(state, (team) => {
            //             return {
            //                 ...team,
            //                 players: team.players.concat({
            //                     ...action.data,
            //                     throws: [],
            //                     throwCount: 0,
            //                     total: 0,
            //                     bucketList: [0,0,0,0,0,0],
            //                     // magicNumber: [null,null,null,null,null,null],
            //                     magicNumber: [ [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null], [null, null, null] ],
            //                     active: false,
            //                     teamId: team.id
            //                 })
            //             }
            //         }),
            //         players: state.players.concat(action.data.id)
            //     }

            // case Actions.REMOVE_PLAYER_FROM_TEAM:
            //     return {
            //         ...state,
            //         gameState: this.updateActiveTeam(state, (team) => {
            //             return {
            //                 ...team,
            //                 players: team.players.filter((player) => player.id !== action.data.id)
            //             };
            //         }),
            //         players: state.players.filter((id) => id !== action.data.id)
            //     }

            // case Actions.SET_ACTIVE_TEAM:
            //     return { ...state, selectPlayersActiveTeam: action.data };

            // case Actions.SORT_PLAYER:
            //     return {
            //         ...state,
            //         gameState: this.updateActiveTeam(state, (team) => {
            //             return {
            //                 ...team,
            //                 players: arrayMove(team.players, action.data.oldIndex, action.data.newIndex)
            //             };
            //         })
            //     };

            // case Actions.BUST_OR_STAY:
            //     return {...state, bustOrStay: action.data };

            // case Actions.BUCKET_LIST_BLUE_BALLS:
            //     return {...state, bucketListBlueBalls: action.data };

            // case Actions.MAGIC_NUMBER_BLUE_BALLS:
            //     return {...state, magicNumberBlueBalls: action.data };

            // case Actions.END_GAME:
            //     return {...state, gameEnded: true };

            // case Actions.SET_GAME_ID:
            //     return {...state, gameId: action.data }; 

            // case Actions.PLAY_AGAIN:
            //     return {
            //         ...state,
            //         gameEnded: false,
            //         gameState: this.clearScoreData(state.gameState), 
            //         currentTeam: this.clearCurrentTeamData(state.currentTeam),
            //         currentThrower: this.clearCurrentThrowerData(state.currentThrower)
            //     }; 

            // case Actions.CHOOSE_NEW_GAME:
            //     return {
            //         ...state,
            //         // gameState: this.clearScoreData(state.gameState),
            //         // teams:[],
            //         // players:[],
            //         // teams: [],
            //         // players: [],
            //         players: [],
            //         gameState:[],
            //         game:null,
            //         scoringStyle:null,
            //         readyToPlay:null,
            //         score:null,
            //         round:null,
            //         currentThrower:null,
            //         currentTeam:null,
            //         modifyGameState:null,
            //         bustOrStay:"Bust",
            //         gameEnded:null,
            //         gameId:null,
            //         selectPlayersActiveTeam:null
            //     }
            
            // case Actions.STORE_TEAM_SETUP:
            //     if (state.scoringStyle === 'Individual') {
            //         return { ...state, individualPlayers: [ ...this.clearScoreData(state.gameState) ], individualAssignedPlayers: [ ...state.players ] }
            //     } else if (state.scoringStyle === 'Team Total') {
            //         return { ...state, teamTotalPlayers: [ ...this.clearScoreData(state.gameState) ], teamTotalAssignedPlayers: [ ...state.players ]  }
            //     } else {
            //         return { ...state, lanePlayers: [ ...this.clearScoreData(state.gameState) ], laneAssignedPlayers: [ ...state.players ]  }
            //     }

            // case Actions.COPY_TEAM_SETUP:
            //     if (state.scoringStyle === 'Individual' && state.individualPlayers) {
            //         return { ...state, gameState: [ ...state.individualPlayers ], players: [ ...state.individualAssignedPlayers ] }
            //     } else if (state.scoringStyle === 'Team Total' && state.teamTotalPlayers) {
            //         // We need to account for changing team names before assigning players.
            //         // teamTotalPlayers will still have the old team names so we need to
            //         // update to the newly set ones
            //         let teamCopy = [...state.teamTotalPlayers];
            //         teamCopy[0].name = state.teams[0];
            //         teamCopy[1].name = state.teams[1];
            //         return { 
            //             ...state, 
            //             gameState: [ ...teamCopy ], 
            //             players: [ ...state.teamTotalAssignedPlayers ]
            //         }
            //     } else if (state.scoringStyle === 'Lane Total' && state.lanePlayers) {
            //         return { ...state, gameState: [ ...state.lanePlayers ], players: [ ...state.laneAssignedPlayers ] }
            //     } else {
            //         return { ...state }
            //     }

            // case Actions.EDIT_PLAYER_BUCKET_SCORE:
            //     return { ...state, currentThrower: this.updateCurrentThrowerBucketScore(state.currentThrower, action.data.score), gameState: this.editBucketScoreForPlayer(action.data.playerId, action.data.score, state.gameState) };
            // case Actions.TEMP_EDIT_PLAYER_BUCKET_SCORE:
            //     return { ...state, modifyGameState: this.editBucketScoreForPlayer(action.data.playerId, action.data.score, state.modifyGameState) };
            // case Actions.UPDATE_PLAYER_BUCKET_SCORE_AFTER_EDIT:
            //     return { ...state, currentThrower: this.updatePlayerBucketScoreAfterEdit(state.currentThrower, state.modifyGameState) };
            // case Actions.EDIT_TEAM_BUCKET_SCORE:
            //     return { ...state, currentTeam: this.updateCurrentTeamBucketScore(state.currentTeam, action.data.score), gameState: this.editBucketScoreForTeam(action.data.teamId, action.data.score, state.gameState) };
            // case Actions.TEMP_EDIT_TEAM_BUCKET_SCORE:
            //     return { ...state, modifyGameState: this.editBucketScoreForTeam(action.data.teamId, action.data.score, state.modifyGameState) };
            // case Actions.UPDATE_TEAM_BUCKET_SCORE_AFTER_EDIT:
            //     return { ...state, currentTeam: this.updateTeamBucketScoreAfterEdit(state.currentTeam, state.modifyGameState) };

            // // case Actions.EDIT_PLAYER_MAGIC_SCORE:
            // //     return { ...state, gameState: this.editMagicScoreForPlayer(action.data.playerId, action.data.score, state.gameState) };
            // case Actions.TEMP_EDIT_PLAYER_MAGIC_SCORE:
            //     return { ...state, modifyGameState: this.editMagicScoreForPlayer(action.data.playerId, action.data.round, action.data.throwCount, action.data.magicNumber, action.data.points, state.modifyGameState) };
            // case Actions.UPDATE_PLAYER_MAGIC_SCORE_AFTER_EDIT:
            //     return { ...state, currentThrower: this.updatePlayerMagicScoreAfterEdit(state.currentThrower, state.modifyGameState) };
            // case Actions.LOG_PLAYER_MAGIC_SCORE:
            //     return { ...state, gameState: this.logMagicScoreForPlayer(action.data.playerId, action.data.round, action.data.index, action.data.score, state.gameState), currentThrower: { ...state.currentThrower, throwCount: state.currentThrower.throwCount + 1} };

            default:
                return state;
        }
    }
}

export default new GameStore();