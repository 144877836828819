import React from 'react';
import Display from '../lib/Display';

export default class ModalMagicNumber extends React.Component {

    closeModal = () => {
        Display.toggleModalKeypad18(false);
    }

    render() {
        return (
            <div className={`modal modal-score-1-8 underlay ${this.props.showModal ? "active" : ""}`}>
                <div className="modal-body">
                    { this.props.clearScore
                        ?
                            <div className="btn btn-gold btn-block" onClick={() => this.props.editPlayerScore(-1)}>Clear Current Round</div>
                        :
                            <div className="keypad">
                                <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(0)}>0</div>
                                <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(1)}>1</div>
                                <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(2)}>2</div>
                                <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(3)}>3</div>
                            </div>
                    }
                    <div className="actions">
                        <div className="btn btn-blank btn-block btn-close" onClick={() => this.props.closeModal()}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}