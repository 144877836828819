import React from 'react';
import Display from '../lib/Display';

export default class Brand extends React.Component {

    checkReservation = () => {
        if (!this.props.reservationStarted) {
            if (this.props.reservation) {
                Display.toggleModalKeypad(true)
            } else {
                alert('There is no current reservation');
            }
        }
    }

    render() {
        return (
            <div id="brand" onClick={this.checkReservation}>    
                <img src={this.props.logo} alt="logo" />
            </div>
        )
    }

}