import React from 'react';
import Display from '../lib/Display';

export default class Modal21Keypad extends React.Component {

    closeModal = () => {
        Display.toggleModalKeypad21(false);
    }

    render() {
        return (
            <div className={`modal modal-score-21 underlay ${this.props.showModalKeypad21 ? "active" : ""}`}>
                <div className="modal-body">
                    <div className="keypad">
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(0)}>0</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(1)}>1</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(2)}>2</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(3)}>3</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(4)}>4</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(5)}>5</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(6)}>6</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(7)}>7</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(8)}>8</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(9)}>9</div>

                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(10)}>10</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(11)}>11</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(12)}>12</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(13)}>13</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(14)}>14</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(15)}>15</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(16)}>16</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(17)}>17</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(18)}>18</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(19)}>19</div>

                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(20)}>20</div>
                        <div className="btn small btn-gold" onClick={() => this.props.editPlayerScore(21)}>21</div>
                    </div>
                    <div className="actions">
                        <div className="btn btn-blank btn-block btn-close" onClick={() => this.props.editPlayerScore(-1)}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}