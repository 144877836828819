// import React from 'react';
// import Views from '../lib/Views';
import Actions from '../lib/Actions';
// import Storage from '../lib/Storage';
import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';

/**
 * Main store for application
 */
class AppStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            initialFetch: false,
            locationId: null,
            laneNumber: null,
            reservationNumber: null,
            reservationName: null,
            welcomeMessage: null,
            reservationDuration: null,
            accessingAdmin: null,
            employeeCodes: [],

            appLogo: "images/axe-arcade.png",
            targetImage: "images/target-no-logo.png",
            adVideo: null,


            // groupInLane: true,
            // startedTraining: true,
            // completedTraining: true,
            // reservationStarted: true,
            groupInLane: null,
            startedTraining: null,
            completedTraining: null,
            reservationStarted: null,




            instructionGame: null,
            currentGame: null,
            completedSetup: null,
            shownTimeWarning: null,
            agreedTimeWarning: null,
            reservationTimeout: null,
            adminOverrideTimeout: null,
            reservationExtended: null,

            hitMessages: ['Hit!'],
            missMessages: ['Miss!'],
            gameRules: [],
            houseRules: null,
            /**
             * All players on the current reservation
             */
            players: []
        };
    }

    reduce(state, action) {
        switch(action.type) {
            case Actions.SET_LOCATION_ID:
                if (!state.groupInLane) {
                    return { ...state, reservationNumber: null, reservationName: null, welcomeMessage: null, locationId: action.data };
                } else {
                    return { ...state, locationId: action.data };
                }
            case Actions.SET_LANE_NUMBER:
                if (!state.groupInLane) {
                    return { ...state, reservationNumber: null, reservationName: null, welcomeMessage: null, laneNumber: action.data };
                } else {
                    return { ...state, laneNumber: action.data };
                }

            case Actions.SET_INITIAL_FETCH:
                return { ...state, initialFetch: true };
            case Actions.SET_APP_LOGO:
                return { ...state, appLogo: action.data };
            case Actions.RESET_APP_LOGO:
                return { ...state, appLogo: "images/axe-arcade.png" };
            case Actions.SET_TARGET_IMAGE:
                return { ...state, targetImage: action.data };
            case Actions.RESET_TARGET_IMAGE:
                return { ...state, targetImage: "images/target-no-logo.png" };
            case Actions.SET_AD_VIDEO:
                return { ...state, adVideo: action.data };
            case Actions.RESET_AD_VIDEO:
                return { ...state, adVideo: null };

            case Actions.UPDATE_RESERVATION_STATUS:
                return { ...state, startedTraining: action.data.startedTraining, completedTraining: action.data.completedTraining }
                
            case Actions.SET_EMPLOYEE_CODES:
                return { ...state, employeeCodes: action.data };
            case Actions.ACCESSING_ADMIN:
                return { ...state, accessingAdmin: action.data };
            case Actions.SET_GAME_RULES:
                return { ...state, gameRules: action.data };
            case Actions.GROUP_IN_LANE:
                return { ...state, groupInLane: true };
            case Actions.STARTED_TRAINING:
                return { ...state, startedTraining: true };
            case Actions.COMPLETED_TRAINING:
                return { ...state, completedTraining: true };
            case Actions.START_RESERVATION:
                return { ...state, reservationStarted: true };
            case Actions.SET_INSTRUCTION_GAME:
                return { ...state, instructionGame: action.data };
            case Actions.SET_CURRENT_GAME:
                return { ...state, currentGame: action.data };
            case Actions.SHOWN_TIME_WARNING:
                return { ...state, shownTimeWarning: action.data };
            case Actions.AGREED_TIME_WARNING:
                return { ...state, agreedTimeWarning: action.data };
            case Actions.LOAD_RESERVATION:
                return {
                    ...state,
                    reservationNumber: action.data.reservationNumber,
                    reservationName: action.data.name,
                    welcomeMessage: action.data.welcomeMessage,
                    reservationDuration: action.data.duration,
                    players: action.data.players.map((player) => { // we use id throughout the app, map that in
                        return { ...player, singleScore: 0, throwCount: 0, id: player.customerId };
                    })
                };
            case Actions.WELCOME_MESSAGE:
                return { ...state, welcomeMessage: action.data }
            case Actions.SET_HIT_MESSAGES:
                let hitMessages  = ['Hit!'];
                let missMessages = ['Miss!'];
                if (action.data.hit.length > 0) {
                    hitMessages = action.data.hit;
                }
                if (action.data.miss.length > 0) {
                    missMessages = action.data.miss;
                }
                return {
                    ...state,
                    hitMessages: hitMessages,
                    missMessages: missMessages
                }
            case Actions.SET_HOUSE_RULES:
                return { ...state, houseRules: action.data }
            case Actions.CHOOSE_NEW_GAME:
                return { ...state, currentGame: null }
            case Actions.RESERVATION_TIMEOUT:
                return { ...state, reservationTimeout: true }
            case Actions.ADMIN_OVERRIDE_TIMEOUT:
                return { ...state, adminOverrideTimeout: action.data }
            case Actions.RESERVATION_EXTENDED:
                return { ...state, reservationExtended: true }
            default:
                return state;
        }
    }
}

export default new AppStore();