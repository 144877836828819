import React from 'react';
import ReactDOM from 'react-dom';
import Lane from './lib/Lane';
import Storage from './lib/Storage';
import AppContainer from './containers/AppContainer'
// import './scss/index.scss';
// import * as serviceWorker from './serviceWorker';

let axeLocation = Storage.getLocationId();
if (axeLocation) {
    Lane.setLocationId(axeLocation);
}

let axeLane = Storage.getLaneNumber();
if (axeLane) {
    Lane.setLaneNumber(axeLane);
}

let axeEmployeeCodes = Storage.getEmployeeCodes();
if (axeEmployeeCodes) {
    Lane.setEmployeeCodes(axeEmployeeCodes);
}

ReactDOM.render(
    <AppContainer />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
