import React from 'react';
import Game from '../lib/Game';

export default class MagicNumberBlueBalls extends React.Component {

    render() {
        return (
            <div className={`button-select-21-style ${!this.props.scoringStyle && this.props.game === 'Magic Number' ? "active" : "inactive"}`}>
                <div className={`btn ${this.props.selection !== true ? "btn-gold" : "btn-outline"}`} onClick={() => Game.setMagicNumberBlueBalls(false)}>No Blue Balls</div>
                <div className={`btn ${this.props.selection === true ? "btn-gold" : "btn-outline"}`} onClick={() => Game.setMagicNumberBlueBalls(true)}>Blue Balls</div>
            </div>
        )
    }
}