import React from 'react';
import Lane from '../lib/Lane';
import Display from '../lib/Display';

export default class ModalTimeWarning extends React.Component {
    closeAndMarkAgreed = () => {
        Lane.agreedTimeWarning();
        Display.toggleTimeWarningModal(false);
    }
    render() {
        return (
            <div className={`modal modal-end underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h2 style={{marginBottom: '25px'}}>Just a quick heads up, your reservation ends in 15 minutes!</h2>
                </div>
            </div>
        )
    }
}