import React from 'react';
import BustOrStay from './BustOrStay';
import BucketListBlueBalls from './BucketListBlueBalls';
import MagicNumberBlueBalls from './MagicNumberBlueBalls';
import GameSetupScoring from './GameSetupScoring';
import GameSetupTeamNames from './GameSetupTeamNames';

export default class GameSetup extends React.Component {
    render() {
        return (
            <div id="game-setup" className={`${this.props.display.showGameSetup ? "active" : "inactive"}`}>
                <h2>{this.props.app.currentGame}</h2>

                <BustOrStay
                    game={this.props.game.game}
                    selection={this.props.game.bustOrStay} 
                    scoringStyle={this.props.game.scoringStyle} /> 

                <BucketListBlueBalls
                    game={this.props.game.game}
                    selection={this.props.game.bucketListBlueBalls} 
                    scoringStyle={this.props.game.scoringStyle} />

                <MagicNumberBlueBalls
                    game={this.props.game.game}
                    selection={this.props.game.magicNumberBlueBalls} 
                    scoringStyle={this.props.game.scoringStyle} /> 
                
                {/* TODO: Comment out */}
                <GameSetupScoring {...this.props} />
                <GameSetupTeamNames {...this.props} />
            </div>
        )
    }
}