import Dispatcher from '../dispatchers/Dispatcher';
import Actions from '../lib/Actions';

export default class Display {

    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static toggleBrowseGames = (value) => {
        this.toggle(Actions.BROWSE_GAMES, value);
    }

    static toggleModalKeypad = (value) => {
        this.toggle(Actions.MODAL_KEYPAD, value);
    }

    static toggleModalKeypad18 = (value) => {
        this.toggle(Actions.MODAL_KEYPAD_18, value);
    }

    static toggleModalKeypad21 = (value) => {
        this.toggle(Actions.MODAL_KEYPAD_21, value);
    }

    static toggleInstructions = (value) => {
        this.toggle(Actions.INSTRUCTIONS, value);
    }

    static toggleAdminModal = (value) => {
        this.toggle(Actions.ADMIN_MODAL, value);
    }

    static toggleAdminChooseActions = (value) => {
        this.toggle(Actions.ADMIN_CHOOSE_ACTIONS, value);
    }

    static toggleGameSetup = (value) => {
        this.toggle(Actions.GAME_SETUP, value);
    }

    static toggleGameSetupScoring = (value) => {
        this.toggle(Actions.GAME_SETUP_SCORING, value);
    }

    static toggleGameSetupTeamNames = (value) => {
        this.toggle(Actions.GAME_SETUP_TEAM_NAMES, value);
    }

    static togglePlayerSetup = (value) => {
        this.toggle(Actions.PLAYER_SETUP, value);
    }

    static toggleShowHitModal = (value) => {
        this.toggle(Actions.HIT_MODAL, value);
    }

    static toggle21WinModal = (value) => {
        this.toggle(Actions.WIN_21_MODAL, value);
    }

    static toggleBustResetModal = (value) => {
        this.toggle(Actions.BUST_RESET_MODAL, value);
    }

    static toggleBustStayModal = (value) => {
        this.toggle(Actions.BUST_STAY_MODAL, value);
    }

    static toggleEndGameModal = (value) => {
        this.toggle(Actions.END_GAME_MODAL, value);
    }

    static toggleTimeWarningModal = (value) => {
        this.toggle(Actions.MODAL_TIME_WARNING, value);
    }

    static toggleRingClosedModal = (message) => {
        this.toggle(Actions.RING_CLOSED_MODAL, { show: true, message: message });
        setTimeout( () => {
            this.toggle(Actions.RING_CLOSED_MODAL, { show: false, message: message });
        }, 1500);
    }

    /**
     * Scoreboards
     */
    static toggleLeagueScoreboard = (value) => {
        this.toggle(Actions.LEAGUE_SCOREBOARD, value);
    }

    //End of game winner's modal
    static hideWinnerModal = () =>  {
        this.toggle(Actions.HIDE_WINNER_MODAL);
    }
}