import React from 'react';

export default class Modal21Win extends React.Component {
    render() {
        return (
            <div className={`modal modal-score underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h1>21!</h1>
                    {/* <h2 style={{paddingBottom: "20px"}}>Staying at {this.props.currentScore} points</h2> */}
                </div>
            </div>
        )
    }
}