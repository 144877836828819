import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';

export default class GameSetupTeamNames extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            team1: 'Team 1',
            team2: 'Team 2'
        }
    }

    updateTeamName = (e) => this.setState({ [e.target.name]: e.target.value });

    validateTeams = () => {
        if (this.state.team1.length > 0 && this.state.team2.length > 0) {
            Game.setTeamNames([this.state.team1, this.state.team2]);
            Game.copyTeamSetup();
            Display.toggleGameSetup(false);
            Display.toggleGameSetupTeamNames(false);
            Display.togglePlayerSetup(true);
        } else {
            alert('Please set valid team names');
        }
    }

    goBack = () => {
        Game.setScoringStyle(null);
        Game.resetGameState();
        Display.toggleGameSetupTeamNames(false);
        Display.toggleGameSetupScoring(true);
        Display.togglePlayerSetup(false);
        Display.toggleGameSetup(true);
    }

    render() {
        return (
            <div id="create-team-names" className={`${this.props.game.scoringStyle && this.props.display.showGameSetupTeamNames ? "active" : "inactive"}`}> 
                {/* style={{zIndex: '999'}} */}
                <div>
                    <div className="field-container team-name-1">
                        <div className="input-group">
                            <label>Team 1</label>
                            <input
                                type="text"
                                name='team1'
                                onChange={this.updateTeamName}
                                value={this.state.team1} 
                                autoComplete="off" />
                        </div>
                    </div>
                    <div className="field-container team-name-2">
                        <div className="input-group">
                            <label>Team 2</label>
                            <input
                                type="text"
                                name='team2'
                                onChange={this.updateTeamName}
                                value={this.state.team2}
                                autoComplete="off" />
                        </div>
                    </div>
                </div>
                <div className="btn btn-gold btn-select-players"  onClick={this.validateTeams}>Select Players</div>
                <div className="btn btn-outline btn-players-back"  onClick={this.goBack}>Go Back</div>
            </div>
        )
    }
}