import Request from './Request';
import Actions from '../lib/Actions';
import Endpoints from '../lib/Endpoints';
import Dispatcher from '../dispatchers/Dispatcher';

export default class Lane {
    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static setLocationId = (locationId) => {
        this.toggle(Actions.SET_LOCATION_ID, locationId);
    }

    static setLaneNumber = (laneNumber) => {
        this.toggle(Actions.SET_LANE_NUMBER, laneNumber);
    }

    static setEmployeeCodes = (codes) => {
        this.toggle(Actions.SET_EMPLOYEE_CODES, codes);
    }

    static toggleAccessingAdmin = (value) => {
        this.toggle(Actions.ACCESSING_ADMIN, value);
    }

    static toggleGroupInLane = (value) => {
        this.toggle(Actions.GROUP_IN_LANE, value);
    }

    static toggleStartedTraining = (value) => {
        this.toggle(Actions.STARTED_TRAINING, value);
    }

    static toggleCompletedTraining = (value) => {
        this.toggle(Actions.COMPLETED_TRAINING, value);
    }

    // Set the game name to show instructions for
    static setInstructionGame = (game) => {
        this.toggle(Actions.SET_INSTRUCTION_GAME, game);
    }

    static setCurrentGame = (game) => {
        this.toggle(Actions.SET_CURRENT_GAME, game);
    }

    static getHitMessages = (locationId) => {
        if (!locationId) {
            return
        }

        // console.log('getting hit messages');
        try {
            Request.get(Endpoints.getHitMessages(locationId)).then((response) => {
                // console.log(response);
                if (response && response.data && response.data.data) {
                    Dispatcher.dispatch({
                        type: Actions.SET_HIT_MESSAGES,
                        data: response.data.data
                    });
                }
            });
        } catch (err) {}
    }

    static getHouseRules = (locationId) => {
        if (!locationId) {
            return
        }

        // console.log('getting house rules');
        try {
            Request.get(Endpoints.getHouseRules(locationId)).then((response) => {
                // console.log(response);
                if (response && response.data && response.data.data) {
                    // console.log('dispatching house rules');
                    Dispatcher.dispatch({
                        type: Actions.SET_HOUSE_RULES,
                        data: response.data.data
                    });
                }
            });
        } catch (err) {}
    }

    static getGameRules = () => {
        Request.get(Endpoints.getGameRules()).then((response) => {
            if (response.status === 200) {
                Dispatcher.dispatch({
                    type: Actions.SET_GAME_RULES,
                    data: response.data.data
                });
            }
        });
    }

    static startReservation = () => {
        this.toggle(Actions.START_RESERVATION, true);
    }  

    static startReservationTime = (duration) => {
        // https://stackoverflow.com/questions/48219925/reactjs-continue-timer-in-the-background
        let now = new Date();
        const second = 1000;
        // 30?
        const minute = 60 * second;
        let warningTime = new Date(now.getTime() + (duration-15) * minute);
        let expiryTime  = new Date(now.getTime() + duration * minute);
        // let expiryTime  = new Date(now.getTime() + 10 * second);

        let warned, expired = false;
        var refreshId = setInterval(() => {
            if (new Date() > warningTime && !warned) {
                this.toggle(Actions.MODAL_TIME_WARNING, true);
                this.toggle(Actions.SHOWN_TIME_WARNING, true);
                warned = true;
            }
            if (new Date() >= expiryTime && !expired) {
                this.toggle(Actions.RESERVATION_TIMEOUT, true);
                expired = true;
            }
            if (warned && expired) {
                clearInterval(refreshId);
            }
        }, 60000)

    }

    // Might not need if we toggle in startReservationTime()
    static shownTimeWarning = () => {
        this.toggle(Actions.SHOWN_TIME_WARNING, true);
    }

    static agreedTimeWarning = () => {
        this.toggle(Actions.AGREED_TIME_WARNING, true);
    }

    // static toggleModalKeypad = (value) => {
    //     this.toggle(Actions.MODAL_KEYPAD, value);
    // }

    // static toggleInstructions = (value) => {
    //     this.toggle(Actions.INSTRUCTIONS, value);
    // }

    // static RESERVATION_TIMEOUT = 'reservation-timeout'

    static adminOverrideTimeout = (val) => {
        this.toggle(Actions.ADMIN_OVERRIDE_TIMEOUT, val);
    }

    static extendReservation = () => {
        this.toggle(Actions.RESERVATION_EXTENDED);
    }

    // static RESERVATION_EXTENDED = 'reservation-extended'
}
