import React from 'react';

export default class ModalPlayerCompleted extends React.Component {
    render() {
        return (
            <div className={`modal modal-score underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h2 style={{paddingBottom: "20px"}}>{this.props.message}</h2>
                </div>
            </div>
        )
    }
}