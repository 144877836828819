import React from 'react';
import Lane from '../lib/Lane';
import Game from '../lib/Game';
import Display from '../lib/Display';

export default class GameSetupScoring extends React.Component {
    setScoringStyle = (style) => {
        Game.setScoringStyle(style);
        Display.toggleGameSetupScoring(false);
        if (style === 'Individual' || style === 'Lane Total') {
            Game.copyTeamSetup();
            Display.toggleGameSetup(false);
            Display.togglePlayerSetup(true);
        }
    }

    goBack = () => {
        Lane.setCurrentGame(null);
        Game.setGame(null);
        Game.setScoringStyle(null);
        Display.toggleGameSetupScoring(false);
        Display.toggleGameSetup(false);
        Display.toggleBrowseGames(true);
    }

    showInstructions = () => {
        Lane.setInstructionGame(this.props.game.game)
        Display.toggleInstructions(true);
    }

    render() {
        return (
            <div id="scoring" className={`button-stack button-stack-single ${this.props.display.showGameSetupScoring ? "active" : "inactive"} ${Game.hasModifiers(this.props.game.game) ? "has-modifiers" : "no-modifiers"}`}>
                <div className="btn btn-gold" onClick={() => this.setScoringStyle('Individual')}>Individual</div>
                <div className="btn btn-gold" onClick={() => this.setScoringStyle('Team Total')}>Team Total</div>
                <div className="btn btn-gold" onClick={() => this.setScoringStyle('Lane Total')}>Lane Total</div>
                <div className="btn btn-gold" onClick={() => this.showInstructions()}>How to Play</div>
                <div className="btn btn-outline" onClick={this.goBack}>Choose Another Game</div>
            </div>
        )
    }
}