// import React from 'react';
// import Views from '../lib/Views';
import Actions from '../lib/Actions';
// import Storage from '../lib/Storage';
import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import ScoringStyle from '../lib/ScoringStyle';

/**
 * Main store for view states
 */
class DisplayStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            showReservation: null,

            // showBrowseGames: true,
            showBrowseGames: null,
            

            showGameSetup: null,
            showGameSetupScoring: null,
            showGameSetupTeamNames: null,
            showPlayerSetup: null,
            showInstructions: null,
            showAdminModal: null,
            showModalKeypad: null,
            showModalKeypad18: null,
            showModalKeypad21: null,
            showModalTimeWarning: null,
            showAdminChooseActions: null,
            showSelectPlayers: null,
            showHitModal: null,
            showRingClosedModal: null,
            ringClosedMessage: null,
            show21WinModal: null,
            showBustStayModal: null,
            showBustResetModal: null,
            showEndGameModal: null,
            showPlayerStatusModal: null,
            playerStatusMessage: null,
            // Scoreboards
            showLeagueScoreboard: null,
            // End of game
            showWinnerModal: null,
            winnerMessage: null,
            // Reservation timeout
            showReservationTimeout: null,
        };
    }

    leagueStyleEndGame = (data) => {
        let winMessage = '';
        if (data.scoringStyle === 'Lane Total') {
            winMessage = 'Your lane has finished with ' + data.winnerScore + ' points!';
        }
        else {
            if (data.winnerName.length > 1) {
                winMessage = data.winnerName.join(', ') + ' have tied with ' + data.winnerScore + ' points!';
            } else {
                winMessage = data.winnerName + ' has won with ' + data.winnerScore + ' points!';
            }
        }
        return winMessage;
    }

    reduce(state, action) {
        switch(action.type) {
            case Actions.BROWSE_GAMES:
                return { ...state, showBrowseGames: action.data };
            case Actions.MODAL_KEYPAD:
                return { ...state, showModalKeypad: action.data };
            case Actions.MODAL_KEYPAD_18:
                return { ...state, showModalKeypad18: action.data };
            case Actions.MODAL_KEYPAD_21:
                return { ...state, showModalKeypad21: action.data };
            case Actions.MODAL_TIME_WARNING:
                return { ...state, showModalTimeWarning: action.data };
            case Actions.INSTRUCTIONS:
                return { ...state, showInstructions: action.data };
            case Actions.ADMIN_MODAL:
                return { ...state, showAdminModal: action.data };
            case Actions.ADMIN_CHOOSE_ACTIONS:
                return { ...state, showAdminChooseActions: action.data };
            case Actions.GAME_SETUP:
                return { ...state, showGameSetup: action.data };
            case Actions.GAME_SETUP_SCORING:
                return { ...state, showGameSetupScoring: action.data };
            case Actions.GAME_SETUP_TEAM_NAMES:
                return { ...state, showGameSetupTeamNames: action.data };
            case Actions.PLAYER_SETUP:
                return { ...state, showPlayerSetup: action.data };
            case Actions.HIT_MODAL:
                return { ...state, showHitModal: action.data };
            case Actions.WIN_21_MODAL:
                return { ...state, show21WinModal: action.data };
            case Actions.BUST_RESET_MODAL:
                return { ...state, showBustResetModal: action.data };
            case Actions.BUST_STAY_MODAL:
                return { ...state, showBustStayModal: action.data };
            case Actions.END_GAME_MODAL:
                return { ...state, showEndGameModal: action.data };
            case Actions.RING_CLOSED_MODAL:
                return { ...state, showRingClosedModal: action.data.show, ringClosedMessage: action.data.message };
            case Actions.SET_PLAYER_STATUS_MODAL:
                return { ...state, showPlayerStatusModal: action.data.showModal, playerStatusMessage: action.data.message };
            // Scoreboards
            case Actions.LEAGUE_SCOREBOARD:
                return { ...state, showLeagueScoreboard: action.data };
            case Actions.SET_SCORING_STYLE:
                if (action.data === null) {
                    return {
                        ...state,
                        showGameSetupTeamNames: null,
                        showPlayerSetup: null
                    };
                } else {
                    const scoringShowTeam = ScoringStyle.styleRequiresTeams(action.data);
                    return {
                        ...state,
                        showGameSetupTeamNames: scoringShowTeam,
                        showPlayerSetup: !scoringShowTeam
                    };
                }
            case Actions.WINNER_MESSAGE:
                if (action.data.game === 'League Style' || action.data.game === 'Magic Number') {
                    return { ...state, showWinnerModal: true, winnerMessage: this.leagueStyleEndGame(action.data) };
                } else {
                    return { ...state }
                }
            case Actions.HIDE_WINNER_MODAL:
                return  { ...state, showWinnerModal: false };
            case Actions.CHOOSE_NEW_GAME:
                return {
                    ...state,
                    showBrowseGames: true
                }
            case Actions.RESERVATION_TIMEOUT:
                return { ...state, showReservationTimeout: action.data }
            case Actions.RESERVATION_EXTENDED:
                return { ...state, showReservationTimeout: false }
            default:
                return state;
        }
    }
}

export default new DisplayStore();