import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Dispatcher from '../dispatchers/Dispatcher';

export default class BucketList {

    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static updatePlayerBucketScoreAfterEdit = () => {
        this.toggle(Actions.UPDATE_PLAYER_BUCKET_SCORE_AFTER_EDIT);
    }

    static updateTeamBucketScoreAfterEdit = () => {
        this.toggle(Actions.UPDATE_TEAM_BUCKET_SCORE_AFTER_EDIT);
    }

    // Based on the points value coming in, we can determine which
    // index to access to modify the appropriate score. 1-4 matches the rings,
    // 6 is a bullseye (index 4), and 'blue' is the blue ball (index 5)
    static calculateBucketIndex = (pts) => {
        if (pts === 'blue') {
            return 5;
        } else if (pts === 6) {
            return 4;
        } else {
            return pts - 1;
        } 
    }

    static evalHitAction = (player, team, scoringStyle, blueBalls, pts) => {
        let index = this.calculateBucketIndex(pts);
        if (scoringStyle === 'Individual') {
            let bucketScore = [...player.bucketList];
            bucketScore[index] = bucketScore[index] += 1;
            // let hitData = { playerId: player.id, teamId: team, score: bucketScore };
            // this.toggle(Actions.EDIT_PLAYER_BUCKET_SCORE, hitData);
            if (bucketScore[index] > 3) {
                // TODO: make this actual ring if we want to show specifics
                Display.toggleRingClosedModal('Ring is already closed!');
            }
        } else {
            let bucketScore = [...team.bucketList];
            bucketScore[index] = bucketScore[index] += 1;
            // let hitData = { teamId: team.id, score: bucketScore };
            // this.toggle(Actions.EDIT_TEAM_BUCKET_SCORE, hitData);
            if (bucketScore[index] > 3) {
                // TODO: make this actual ring if we want to show specifics
                Display.toggleRingClosedModal('Ring is already closed!');
            }
        }

        return false;
    }

    static getBucketScore = (index, data) => {
        if (data.bucketList[index] === 0) {
            return '';
        } else if (data.bucketList[index] === 1) {
            return '/'
        } else if (data.bucketList[index] === 2) {
            return 'X'
        } else {
            return 'ⓧ';
        }
    }

    static editTempScoreForPlayer = (bucketIndex, playerData) => {
        let bucketScore = [...playerData.bucketList];
        if (bucketScore[bucketIndex] >= 3) {
            bucketScore[bucketIndex] = 0;
        } else {
            bucketScore[bucketIndex] = bucketScore[bucketIndex] + 1;
        }
        let hitData = { playerId: playerData.id, score: bucketScore }
        this.toggle(Actions.TEMP_EDIT_PLAYER_BUCKET_SCORE, hitData);
    }

    static editTempScoreForTeam = (bucketIndex, teamData) => {
        let bucketScore = [...teamData.bucketList];
        if (bucketScore[bucketIndex] >= 3) {
            bucketScore[bucketIndex] = 0;
        } else {
            bucketScore[bucketIndex] = bucketScore[bucketIndex] + 1;
        }
        let hitData = { teamId: teamData.id, score: bucketScore }
        this.toggle(Actions.TEMP_EDIT_TEAM_BUCKET_SCORE, hitData);
    }

    // There really isn't a good way to show this for this game
    static getPlayerDisplayScore = (playerData) => {
        return playerData.throwCount + " throws";
    }

    static getTeamDisplayScore = (teamData, includeBlueBalls) => {
        // return teamData ? " - " + teamData.throwCount + " throws" : '';
        return teamData ? " - " + teamData.throwCount : '';
    }

    static playerGameStatus = (playerData, includeBlueBalls) => {
        let completed = true;
        playerData.bucketList.forEach(function(score, index) {
            if (score < 3) {
                if (index === 5 && includeBlueBalls) {
                    completed = false;
                } else if (index === 5 && !includeBlueBalls) {
                    
                } else {
                    completed = false;
                }
            }
        })
        return completed;
    }

    static editTempThrowsForPlayer = (playerId, throws) => {
        let hitData = { playerId: playerId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_PLAYER_THROWS, hitData);
    }

    static editTempThrowsForTeam = (teamId, throws) => {
        let hitData = { teamId: teamId, throws: throws }
        this.toggle(Actions.TEMP_EDIT_TEAM_THROWS, hitData);
    }
}