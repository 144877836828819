import React from 'react';
import Lane from '../lib/Lane';
import Display from '../lib/Display';

export default class ModalReservationTimeout extends React.Component {

    overrideTimeout = () => {
        Lane.adminOverrideTimeout(true);
        Display.toggleModalKeypad(true);
    }

    completeReservation = () => {
        Lane.adminOverrideTimeout(false);
        Display.toggleModalKeypad(true);
    }

    render() {
        return (
            <div className={`modal modal-end underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h2 style={{marginBottom: '25px'}}>Your reservation has ended.</h2>
                </div>
            </div>
        )
    }
}